import React from "react";
import classnames from "classnames";

import "./UploadForm.css";
import UploadIcon from "./UploadIcon";
import { iOS, iOSAttributes } from "./iOSHelper";

const supportedFileTypes = [
  // Note: this doesn't do the right thing on iOS, yet
  // https://github.com/WebKit/WebKit/pull/3731/files
  "image/heic",
  "image/heic-sequence",
  "image/heif",
  "image/heif-sequence",
  // Catch-all for image, video, and audio
  "image/*",
  "video/*",
  "audio/*",
];

class UploadForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragHovering: false,
    };

    this.filePicker = React.createRef();

    // This binding is necessary to make `this` work in the callback
    this.clickHandler = this.clickHandler.bind(this);
    this.dragOverHandler = this.dragOverHandler.bind(this);
    this.dropHandler = this.dropHandler.bind(this);
    this.formChange = this.formChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener("dragover", this.dragOverHandler);
    window.addEventListener("drop", this.dropHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("dragover", this.dragOverHandler);
    window.removeEventListener("drop", this.dropHandler);
  }

  clickHandler(event) {
    const fileElem = this.filePicker.current;
    if (fileElem) {
      fileElem.click();
    }
  }

  dragOverHandler(event) {
    event.preventDefault();
    this.hover(true);
    clearTimeout(this.unhoverTimeout);
    this.unhoverTimeout = setTimeout(() => {
      this.hover(false);
    }, 100);
  }

  formChange(event) {
    event.preventDefault();
    const inputForm = event.target;
    [...inputForm.files].forEach((file, i) => {
      this.props.fileSelected(file);
    });
    inputForm.value = null; // reset form
  }

  getFilesWebkit(handle, prefix = []) {
    if (handle.isFile) {
      handle.file(
        (file) => {
          this.props.fileSelected(file, prefix);
        },
        (error) => {
          /* handle error — error is a FileError object */
        }
      );
    } else if (handle.isDirectory) {
      const dirReader = handle.createReader();
      const dir = [...prefix, handle.name];
      dirReader.readEntries(
        (results) => {
          results.forEach((result) => {
            this.getFilesWebkit(result, dir);
          });
        },
        (error) => {
          /* handle error — error is a FileError object */
        }
      );
    }
  }

  dropHandler(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();

    if (event.dataTransfer.items) {
      // DataTransferItemList
      [...event.dataTransfer.items].forEach(async (item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          this.getFilesWebkit(item.webkitGetAsEntry());
        } else {
          console.log("Unknown drop", item);
        }
      });
    }
    // don't use event.dataTransfer.files; it doesn't provide sub-directories
    this.hover(false);
  }

  hover(enable) {
    this.setState({ dragHovering: enable });
    if (enable) {
      document.body.classList.add("hover");
    } else {
      document.body.classList.remove("hover");
    }
  }

  render() {
    const classes = classnames({ hover: this.state.dragHovering });
    const description = !iOS() ? (
      <p>
        You can drag one or more files to the <i>drop zone</i>, or click here to
        select them with the file picker.
      </p>
    ) : iOSAttributes().version < 17 ? (
      <>
        <p>Click here to select photos and videos to upload.</p>
        <p>
          When you select videos, it may take a while after you click &ldquo;<b>Add</b>&rdquo; as
          the videos are converted.
        </p>
      </>
    ) : (
      <p>
        Before every upload, in the <b>Photo Library</b> picker, select
        &ldquo;<b>Options</b>&rdquo; and set &ldquo;<b>Format</b>&rdquo;
        to &ldquo;<b>Current</b>.&rdquo;
      </p>
    );

    return (
      <>
        <form>
          <input
            type="file"
            id="fileElem"
            multiple
            onChange={this.formChange}
            ref={this.filePicker}
            // TODO figure out how to prevent conversion from HEIC to JPEG on iOS 17
            accept={supportedFileTypes.join(",")}
          />
        </form>
        <div id="drop_zone_box" className={classes} onClick={this.clickHandler}>
          <h3>Upload Pictures and Videos</h3>
          <UploadIcon />
          {description}
        </div>
        <div id="drop_zone_full" className={classes} />
      </>
    );
  }
}

export default UploadForm;
