import "./NameEntryForm.scss";
import { useState, useEffect } from "react";
import { GoCheckCircle } from "react-icons/go";

export default function NameEntryForm({ onComplete }) {
  const [name, setName] = useState(window.localStorage.getItem("name") || "");
  const [form, setForm] = useState(name ? false : true);
  const placeholder = "What is your name?";

  // Update name with parent only on first load
  // Note: `onSubmit` will take care of it on future loads
  useEffect(() => {
    onComplete(name);
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) return false;
    onComplete(name);
    window.localStorage.setItem("name", name);
    setForm(false);
  };

  return (
    <div className="name-entry-form">
      {form ? (
        <form onSubmit={onSubmit}>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={onSubmit}
            type="text"
            placeholder={placeholder}
            autoComplete="name"
            autoCorrect="off"
            spellCheck="off"
            autoFocus
          />
          <button type="submit">
            <GoCheckCircle size={26} />
          </button>
        </form>
      ) : (
        <div className="display-name">
          Hi <span onClick={() => setForm(true)}>{name}</span>!
        </div>
      )}

      {/* Enable reset button for debugging only */}
      { process.env.NODE_ENV === "development" && (
        <button
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          reset
        </button>
      )}
    </div>
  );
}
