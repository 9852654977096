// TODO update this to encourage iOS 17
// TODO complete iOS17 walkthrough

// From: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}


function iOSAttributes() {
  const uaRegex = /(?<type>iPhone|iPad) OS (?<version>[1-9]*)/g;
  const result = uaRegex.exec(window.navigator.userAgent);
  console.log(result.groups)
  if (result && result.groups) {
    return {
      type: result.groups.type,
      version: parseInt(result.groups.version),
    };
  }
  return {
    type: "",
    version: 0,
  };
}

export { iOS, iOSAttributes };
