import "./Gallery.css";
import Image from "./Image.js";

export default function Gallery({ uploads }) {
  if (!uploads) return; // Nothing to display
  return (
    <div className="Gallery">
      {uploads
        .filter((u) => u.display)
        .map((u) => (
          <Image
            key={u.uuid}
            src={u.imageUrl}
            videoType={u.videoType}
            videoUrl={u.videoUrl}
            state={u.state}
            progress={u.progress}
            onClick={u.onClick}
            hideImage={u.hideImage}
          />
        ))}
    </div>
  );
}
