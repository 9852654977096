function getVideoFrame(
  fileUrl,
  longEdge = 160 /* px */,
  timeout = 1000 /* ms */
) {
  return Promise.race([
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout getting frame")), timeout)
    ),

    new Promise((resolve, reject) => {
      const video = document.createElement("video");
      let seeked = false;

      // For debugging:
      // registerAllVideoHandlers(video);

      // Seek to beginning of the video
      video.addEventListener("loadedmetadata", () => {
        video.currentTime = 0;
      });

      // Wait for seek to complete
      video.addEventListener("seeked", (event) => {
        seeked = true;
      });

      // Grab the frame after seek completes
      video.addEventListener(/*'seeked'*/ "canplay", (event) => {
        if (!seeked) return;

        // Create a scaled down canvas
        const scaleFactor = Math.min(
          video.videoHeight / longEdge,
          video.videoWidth / longEdge
        );
        const canvas = document.createElement("canvas");
        canvas.height = Math.ceil(video.videoHeight / scaleFactor);
        canvas.width = Math.ceil(video.videoWidth / scaleFactor);

        // Draw the video frame on the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Check to see if center of image is transparent
        const imageData = ctx.getImageData(
          canvas.width / 2,
          canvas.height / 2,
          4,
          4
        );
        if (imageData) {
          const pixels = imageData.data;
          for (let i = 3; i < pixels.length; i += 3) {
            if (pixels[i] === 0) {
              reject("Center of video is transparent");
              return;
            }
          }
        }

        // Generate video frame from canvas as a webp image, 60% quality
        const frameData = canvas.toDataURL("image/webp", 0.6);
        resolve(frameData);
      });

      const rejectFn = (e) => reject(e);
      video.addEventListener("abort", rejectFn);
      video.addEventListener("error", rejectFn);
      video.addEventListener("stalled", rejectFn);

      video.muted = true;
      video.src = fileUrl;
    }),
  ]);
}

export { getVideoFrame };

// For debugging purposes...
/*
function registerAllVideoHandlers(video) {
    video.addEventListener('abort', (event) => {
        console.log(event)
    });
    video.addEventListener('canplay', (event) => {
        console.log(event)
    });
    video.addEventListener('canplaythrough', (event) => {
        console.log(event)
    });
    video.addEventListener('durationchange', (event) => {
        console.log(event)
    });
    video.addEventListener('emptied', (event) => {
        console.log(event)
    });
    video.addEventListener('ended', (event) => {
        console.log(event)
    });
    video.addEventListener('error', (event) => {
        console.log(event)
    });
    video.addEventListener('loadeddata', (event) => {
        console.log(event)
    });
    video.addEventListener('loadedmetadata', (event) => {
        console.log(event)
    });
    video.addEventListener('loadstart', (event) => {
        console.log(event)
    });
    video.addEventListener('pause', (event) => {
        console.log(event)
    });
    video.addEventListener('play', (event) => {
        console.log(event)
    });
    video.addEventListener('playing', (event) => {
        console.log(event)
    });
    video.addEventListener('progress', (event) => {
        console.log(event)
    });
    video.addEventListener('ratechange', (event) => {
        console.log(event)
    });
    video.addEventListener('seeked', (event) => {
        console.log(event)
    });
    video.addEventListener('seeking', (event) => {
        console.log(event)
    });
    video.addEventListener('stalled', (event) => {
        console.log(event)
    });
    video.addEventListener('suspend', (event) => {
        console.log(event)
    });
    video.addEventListener('timeupdate', (event) => {
        console.log(event)
    });
    video.addEventListener('volumechange', (event) => {
        console.log(event)
    });
    video.addEventListener('waiting', (event) => {
        console.log(event)
    });
}

*/
